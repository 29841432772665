<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                Detail Pricing
            </b-card-header>
            <b-card-body>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row class="mb-4">
                    <b-col sm="4">
                        <h6>Created By</h6>
                        <div class="mb-3" v-if="this.result.created_by == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.created_by }}</strong></div>
                        <h6>Durasi aktif</h6>
                        <div class="mb-3" v-if="this.result.duration == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.duration }} menit</strong></div>
                        <h6>Type Margin</h6>
                        <div class="mb-3" v-if="this.result.type == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.type.toUpperCase() }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Akan aktif pada</h6>
                        <div class="mb-3" v-if="this.result.actived_at == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.actived_at }}</strong></div>
                        <h6>Type</h6>
                        <div class="mb-3" v-if="this.result.price_type == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.price_type.toUpperCase() }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Akan berakhir pada</h6>
                        <div class="mb-3" v-if="this.result.expired_at == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.expired_at }}</strong></div>
                        <h6 v-if="this.result.price_type == 'fixed'">Amount IDR (Rp)</h6>
                        <div class="mb-3" v-if="this.result.amount == null" v-show="this.result.price_type == 'fixed'"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.amount | currency }}</strong></div>
                    </b-col>
                </b-row>
                <b-row class="mb-4" v-if="this.result.type == 'percentage'">
                    <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.result.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_ask }}%</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.result.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_bid }}%</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.result.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.trading_fee }}%</strong></div>
                    </b-col>
                </b-row>
                <b-row class="mb-4" v-if="this.result.type == 'amount'">
                     <b-col sm="4">
                        <h6>Margin Buy (IDR)</h6>
                        <div class="mb-3" v-if="this.result.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_ask_combination | currency }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Margin Sell (IDR)</h6>
                        <div class="mb-3" v-if="this.result.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_bid_combination | currency }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Trading Fee (IDR)</h6>
                        <div class="mb-3" v-if="this.result.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.trading_fee_combination | currency }}</strong></div>
                    </b-col>
                </b-row>
                <b-row class="mb-4" v-if="this.result.type == 'combination'">
                    <b-col sm="4">
                        <h6>Margin Buy (%)</h6>
                        <div class="mb-3" v-if="this.result.margin_ask == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_ask }}%</strong></div>
                        <h6>Margin Buy (IDR)</h6>
                        <div class="mb-3" v-if="this.result.margin_ask_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_ask_combination | currency }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Margin Sell (%)</h6>
                        <div class="mb-3" v-if="this.result.margin_bid == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_bid }}%</strong></div>
                        <h6>Margin Sell (IDR)</h6>
                        <div class="mb-3" v-if="this.result.margin_bid_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.margin_bid_combination | currency }}</strong></div>
                    </b-col>
                    <b-col sm="4">
                        <h6>Trading Fee (%)</h6>
                        <div class="mb-3" v-if="this.result.trading_fee == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.trading_fee }}%</strong></div>
                        <h6>Trading Fee (IDR)</h6>
                        <div class="mb-3" v-if="this.result.trading_fee_combination == null"><strong>-</strong></div>
                        <div class="mb-3" v-else><strong>{{ this.result.trading_fee_combination | currency }}</strong></div>
                    </b-col>
                </b-row>
                <b-row class="mb-4" v-if="this.result.priceAfterPricing != null">
                  <b-col sm="6">
                    <h5 class="text-center">Harga Beli setelah custom pricing</h5>
                    <div class="mb-3 text-center" v-if="this.result.priceAfterPricing.buying_rate == null"><strong>-</strong></div>
                    <div class="mb-3 text-center" v-else><strong>{{ this.result.priceAfterPricing.buying_rate | currency }}</strong></div>
                  </b-col>
                  <b-col sm="6">
                    <h5 class="text-center">Harga Jual setelah custom pricing</h5>
                    <div class="mb-3 text-center" v-if="this.result.priceAfterPricing.selling_rate == null"><strong>-</strong></div>
                    <div class="mb-3 text-center" v-else><strong>{{ this.result.priceAfterPricing.selling_rate | currency }}</strong></div>
                  </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'detail-pricing',
    data() {
        return {
          result: [],
          errors: {
              message: [],
              status: [],
              code: '',
              headers: [],
          },
          isLoading: false
        }
    },
    created () {

        this.$http.get(`pricing-detail/` + this.$route.params.id)
        .then((result) => {
          this.result = result.data.data
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    },
}
</script>

<style>
    .submt {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
